import { action, makeObservable, observable } from "mobx"

class OverviewStore {
	input = ""

	constructor() {
		makeObservable(this, {
			input: observable,
			setInput: action,
		})
	}

	setInput(input) {
		this.input = input
	}

	dehydrate() {
		return {
			input: this.input,
		}
	}
}

const store = new OverviewStore()

export default store
