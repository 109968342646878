import { Provider } from "mobx-react"
import React from "react"

import { CompanyViewStore, OverviewStore } from "../store"

const StoreProvider = ({ element }) => (
	<Provider CompanyViewStore={CompanyViewStore} OverviewStore={OverviewStore}>
		{element}
	</Provider>
)

export default StoreProvider
