import ReactDOM from "react-dom/client"
import provideStores from "./src/state/provider/provide-stores"
import "./src/styles/index.scss"

export const wrapRootElement = provideStores

export const replaceHydrateFunction = () => {
	return (element, container) => {
		const root = ReactDOM.createRoot(container)
		root.render(element)
	}
}
