import animateScrollTo from "animated-scroll-to"
import { action, makeObservable, observable } from "mobx"

class CompanyViewStore {
	activeCompanyId: number | null = null

	constructor() {
		makeObservable(this, {
			activeCompanyId: observable,
			setActiveCompanyId: action,
			removeActiveCompanyId: action,
		})
	}

	setActiveCompanyId(companyId: number | null, scrollUp = true) {
		this.activeCompanyId = companyId

		if (scrollUp) {
			animateScrollTo(0).then(() => {})
		}
	}

	removeActiveCompanyId() {
		this.activeCompanyId = null
	}

	dehydrate() {
		return {
			activeCompanyId: this.activeCompanyId,
		}
	}
}

const store = new CompanyViewStore()

export default store
